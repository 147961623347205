import React from "react";
import RecordRTC from "recordrtc";
import { Button, Tabs, Tab, Form, Container, Row, Col } from "react-bootstrap";
import ReactMarkdown from "react-markdown"
import recVideo from "@images/blink-record.gif"
import {
    isIOS,
    isMobileOnly,
    isBrowser,
    isMobile,
    isChrome,
    isFirefox,
    isSafari
} from "react-device-detect";
class VideoStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {
                video: true,
                audio: true
            },
            videoSrc: "",
            startVideo : false,
            stopVideo : false,
            saveVideo: false,
            videoBlob: null,
            recIcon : false,
            videoFile : null,
            pulseVideo: false,
            videopermissions: '',
            isbrowser: ''

        }
        this.recorder = null;
    }
    onSuccess = (stream) => {
        this.videoEl.srcObject = stream;
    }
    onError = (err) => {
        // console.log(err)
    }
    // startRecord = () => {
    //     this.recorder = navigator.getUserMedia(this.state.options, this.onSuccess, this.onError)
    // }
    startRecord = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Record Video',
          'formType': 'Careers page',
          'formId': 'Record Video',
          'formName': 'Record Video',
          'formLabel': 'Record Video'
        });
        navigator.mediaDevices.getUserMedia(this.state.options).then((stream) => {
            // console.log(stream, "Strem")
            this.props.setvideotrue(true)
            this.videoEl.srcObject = stream;
            this.recorder = RecordRTC(stream, {
                type: "video",
                checkForInactiveTracks: true,
                mimeType: 'video/webm',
                // onStateChanged: (state) => {
                //     this.setState({
                //         recIcon: true
                //     })
                // }
                
            })
            this.recorder.setRecordingDuration(30000).onRecordingStopped(this.autoStopRecord);
            this.recorder.startRecording()
            // this.setState({ videoSrc: window.URL.createObjectURL(stream) })
        }).catch(err => {
            // console.log(err)
            this.props.setvideotrue(false)
            this.setState({
                videoSrc: "",
            startVideo: false,
            stopVideo: false,
            videoBlob: "",
            pulseVideo: false,
            videopermissions:'denied'
            })
        })
        this.setState({
            stopVideo : false,
            startVideo: true
            
        }, () => {
            setTimeout(() => this.setState({
                recIcon: true
            }), 2000)
        })
    }
    autoStopRecord = () => {
        
            let blob = this.recorder.getBlob();
            this.setState({
                recIcon: false
            })
            let videofile = new File([blob], "video", {type : 'video/webm'})
            // console.log(videofile)
            this.setState({ videoFile : videofile, stopVideo : true, startVideo: false, videoBlob: blob, videoSrc: URL.createObjectURL(blob) })
            this.stopCamera()
            // this.props.setvideotrue(false)
        
        }
    pauseRecord = () => {
        this.recorder.pauseRecording()
        this.setState({
            pulseVideo: true
        })
    }
    resumeRecord = () => {
        this.recorder.resumeRecording()
        this.setState({
            pulseVideo: false
        })
    }
    stopCamera = () => {
        const stream = this.videoEl?.srcObject
        const tracks = stream?.getTracks()
        tracks.forEach(track => {
            track.stop()
        });
        this.videoEl.srcObject = null
    }
    stopRecord = () => {

        this.recorder.stopRecording(() => {
            let blob = this.recorder.getBlob();
            this.setState({
                recIcon: false
            })
            let videofile = new File([blob], "video", {type : 'video/webm'})
            // console.log(videofile)
            this.setState({ pulseVideo: false,videoFile : videofile, stopVideo : true, startVideo: false, videoBlob: blob, videoSrc: URL.createObjectURL(blob) })
            // this.props.setvideotrue(false)
        })
        this.stopCamera()
    }
    deleteVideo = () => {
        // if(this.state.startVideo){
        //     this.stopCamera()
        // }
        this.setState({
            videoSrc: "",
            startVideo : false,
            stopVideo : false,
            videoBlob: "",
            pulseVideo: false
        })
        this.props.setvideotrue(false)
    }
    saveRecord = () => {
        debugger
        this.setState({
            startVideo : false,
            stopVideo : false,
            saveVideo: true,
            pulseVideo: false
        })
        this.props.setvideoblob(this.state.videoFile)
        this.props.setvideotrue(false)
        this.props.setisVideo(true)
    }
    componentDidMount() {
        if(!isSafari){
        navigator.permissions.query({ name: 'camera' }).then((result) => {
            if (result && result.state) {
                this.setState({
                    videopermissions: result.state
                })
            }
        });
        }
    }
    render() {
        return (
            <>
            <Row>
            <Col sm={this.props.stepcamera ? 12: 12} lg={this.props.stepcamera ? 6 : 6} xl={this.props.stepcamera ? 6 : 4} className={this.props.stepcamera ? "" : "ml-lg-auto"}>
                        <div style={{ backgroundImage: `url("${this.props.data?.glstrapi?.recordVideo?.Image?.url}")` }} className="record-video record-video-step">
                        {!this.state.startVideo && !this.state.stopVideo ?
                        <>
                            <h3>{this.props.data?.glstrapi?.recordVideo?.Title}</h3>
                            <div className="cont">
                            <ReactMarkdown source={this.props.data?.glstrapi?.recordVideo?.Content} allowDangerousHtml />
                            </div>
                            {this.state.saveVideo ? <><div className="alert-success">Your Video saved successfully</div>
                            <video src={this.state.videoSrc} controls playsInline /></> 
                            : this.state.videopermissions === 'denied' ? <p className="color-red-text">{isChrome?<>You have denied access to the mic and camera, please allow your mic and camera in browser &#8594; Site settings &#8594; Permissions &#8594; Camera / Microphone &#8594; Delete the website entry listed under "Block", then click the button above again to proceed the video.</>: isFirefox ? <>You have denied access to the mic and camera, please allow your mic and  camera in browser &#8594; Click on "Show Site Information" icon in browser &#8594; Delete the entries "Use the Microphone" which is blocked temporarily and "Use the camera" which is again blocked temporarily, then click the button above again to proceed the video.</> : null}</p>
                            : <button className="btn btn-primary" onClick={this.startRecord}>Record Video</button>}
                            </>
                            : 
<>                            

{!this.state.startVideo ? <Button className="btn btn-primary" onClick={this.startRecord}>{!this.state.startVideo && !this.state.videoBlob ? 'Record Video' : 'Retake'}</Button> : null}
                {this.state.startVideo ? <>
                <Button className="btn btn-primary" onClick={this.stopRecord}>Stop Video</Button>
                
                </>: null}
                {this.state.stopVideo ?<video src={this.state.videoSrc} autoPlay controls playsInline /> : null}
                <div class="recored" style={{display: this.state.stopVideo ? "none" : "block" }}>
                 {!this.state.recIcon ? null : !this.state.pulseVideo ?
                <Button className="pause-btn" onClick={this.pauseRecord}>Pause Record</Button> :
                <Button className="resume-btn" onClick={this.resumeRecord}>Resume Record</Button> }
                    {!this.state.pulseVideo && this.state.recIcon ?
                     <div className="rec-icon" style={{ backgroundImage: `url("${recVideo}")` }}></div>
                      : null}
                      <video id="video"   ref={ref => this.videoEl = ref} autoPlay  playsInline/></div>
                {this.state.stopVideo && this.state.stopVideo ? <div className="cam-btn-blk"><Button className="btn btn-primary" onClick={this.saveRecord}>Save</Button><Button className="btn" onClick={this.deleteVideo}>Delete</Button></div> : null}
                </> }
                        </div>
                    </Col>

                    </Row>
            </>
        )
    }
}

export default VideoStep;